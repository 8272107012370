
import { Options, Vue } from "vue-class-component";
import { Answer, SelectedAnswer } from "../types/Questionnaire";

@Options({
  props: {
    name: { required: true, type: String },
    classes: { type: String },
    labelClasses: { default: "text-secondary", type: String },
    description: { default: null, type: String },
    optionClasses: { default: "space-x-2", type: String },
    model: { required: true },
    label: { required: false, type: String }, // Make label an optional prop
    options: { required: true, type: Array },
    hasError: { required: true, type: Boolean },
    remoteSelected: { required: false, type: String },
    remoteText: { required: false, type: String },
    error: { required: true }
  },
  data() {
    return {
      internalModel: [],
      selected: null,
      textInputValue: ""
    };
  },
  methods: {
    async updateModel(option: Answer) {
      this.internalModel = [
        {
          question: this.name,
          answer: option.value,
          points: option.points,
          text: this.textInputValue // Update with the value of the text input
        }
      ];

      this.$emit("update:model", this.internalModel);
    },
    async updateCustomText(option: Answer, text: string) {
      let index = this.internalModel.findIndex((i: SelectedAnswer) => i.answer === option.value);

      if (index !== -1) {
        this.internalModel[index].text = text;
        this.textInputValue = text; // Update the data property with the new text value
        this.$emit("update:model", this.internalModel);
      }
    }

  },
  watch: {
    name() {
      this.internalModel = [];
      this.selected = null;
    },
    model() {
      if (this.model[0]?.question === "intermediaryPerson") {
        console.log("Model changed", this.model);
        this.selected = this.model[0]?.answer;
        this.textInputValue = this.remoteText; // Update textInputValue with remoteText when model changes
      }
    },
    internalModel() {
      this.$emit("update:model", this.internalModel);
      console.log("Internal model:", this.internalModel);
      this.selected = this.internalModel[0]?.answer;
      console.log("Selected:", this.selected);
    },
    remoteSelected(newVal) {
      console.log("New remote selected:", newVal);
      this.updateModel(this.options.find((i: Answer) => i.value === this.remoteSelected));
      if (this.remoteSelected) {
        this.selected = this.remoteSelected;
      }
    },
    remoteText(newVal) {
      if (this.internalModel.length && this.internalModel[0]?.text !== newVal) {
        this.internalModel[0].text = newVal;
        this.textInputValue = newVal; // Update textInputValue with remoteText when it changes externally
        console.log("Remote text changed:", newVal);
        this.$emit("update:model", this.internalModel);
      }
    }
  }
})
export default class FormRadioGroup extends Vue {
}
