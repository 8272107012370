
import { Options, Vue } from "vue-class-component";
import SectionTitle from "@/shared/components/SectionTitle.vue";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";
import PersonalInfoForm from "@/components/forms/PersonalInfoForm.vue";
import { mapGetters } from "vuex";

@Options({
  components: {
    SectionTitle,
    PersonalInfoForm,
    LeftStepBar,
  },
  beforeMount() {
    this.$store.commit("onboarding/checkProfile");
  },
  mounted() {
    if (!this.$store.getters["onboarding/allCountries"].length) {
      this.$store.dispatch("onboarding/getCountries");
    }
    this.$store.dispatch("onboarding/getProfile");
  },
  computed: {
    ...mapGetters({
      riskProfile: "onboarding/myProfile",
    }),
  },
})
export default class PersonalInfo extends Vue {}
