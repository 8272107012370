import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "md:flex justify-between" }
const _hoisted_2 = { class: "md:w-8/12 w-full relative background-cube inner-page pb-20" }
const _hoisted_3 = { class: "md:w-8/12" }
const _hoisted_4 = { class: "md:w-4/12 md:pt-32 mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_LeftStepBar = _resolveComponent("LeftStepBar")!
  const _component_PersonalInfoForm = _resolveComponent("PersonalInfoForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SectionTitle, {
          class: "w-full",
          text: _ctx.$t('mainTitle')
        }, null, 8, ["text"]),
        _createVNode(_component_LeftStepBar, {
          step: 2,
          title: _ctx.$t('personal_info.title'),
          perex: _ctx.$t('personal_info.perex'),
          description1: _ctx.$t('personal_info.description1')
        }, null, 8, ["title", "perex", "description1"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_PersonalInfoForm)
    ])
  ]))
}