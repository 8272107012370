import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full text-secondary" }
const _hoisted_2 = ["onClick", "value"]
const _hoisted_3 = {
  key: 0,
  class: "text-danger text-xs absolute mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePicker = _resolveComponent("DatePicker")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`text-primary-300 relative text-${_ctx.textSize}`)
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createVNode(_component_DatePicker, {
      mode: "date",
      onDayclick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateModel($event))),
      modelValue: _ctx.modelValue,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.modelValue = $event)),
      "model-config": _ctx.modelConfig,
      masks: _ctx.masks
    }, {
      default: _withCtx(({ inputValue, togglePopover, inputEvents }) => [
        _createElementVNode("input", _mergeProps({
          onClick: ($event: any) => (togglePopover()),
          onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateModelValue($event.target.value))),
          value: inputValue
        }, _toHandlers(inputEvents), { class: "\n            cursor-pointer\n            w-full\n            block\n            bg-transparent\n            focus:outline-none\n            border-b-2 border-secondary\n          " }), null, 16, _hoisted_2),
        (_ctx.hasError)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.error.$message), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "model-config", "masks"])
  ], 2))
}