
import useVuelidate from "@vuelidate/core";
import { required } from "@/utils/i18n-validators";
import { Options, Vue } from "vue-class-component";
import FormInput from "@/shared/components/FormInput.vue";
import FormCheckbox from "@/shared/components/FormCheckbox.vue";
import FormSelect from "@/shared/components/FormSelect.vue";
import FormRadioGroup from "@/shared/components/FormRadioGroup.vue";
import CalendarInput from "@/shared/components/CalendarInput.vue";
import { helpers } from "@vuelidate/validators";
import { maxDate, minDate } from "@/utils/validators";
import { mapGetters } from "vuex";

@Options({
  data() {
    return {
      v$: useVuelidate(),
      formSubmitted: false,
      investmentOptions: [
        { value: true, name: this.$t("yes") },
        { value: false, name: this.$t("no") },
      ],
      formDefault: {
        title: "",
        firstName: "",
        lastName: "",
        birthdate: "",
        birthnumber: "",
        idCardNumber: "",
        idCardValidity: "",
        nationality: "",
        phoneNumber: "",
        smsCode: "",
        childInvestment: "",
        intermediaryPerson: "",
        SFA: "",
        is_from_wem: false,
      },
      disabledPhone: false,
    };
  },
  components: {
    FormInput,
    FormCheckbox,
    FormSelect,
    FormRadioGroup,
    CalendarInput,
  },
  computed: {
    countries() {
      return this.$store.getters["onboarding/allCountries"];
    },
    isValidPhoneNumber() {
      const phoneNumberRegex = /^42[0-9]{10}$/;
      return phoneNumberRegex.test(this.phoneNumber);
    },
    form() {
      if (!this.$store.state.onboarding.profile) return this.formDefault;
      return this.$store.state.onboarding.profile;
    },
    ...mapGetters({
      riskProfile: "onboarding/myProfile",
    }),
    titleKey() {
      if (typeof localStorage !== "undefined") {
        return localStorage.getItem("wem_locale") === "en" ? "name_en" : "name";
      }
      return "name"; // Fallback value when localStorage is not available
    },
  },
  validations() {
    return {
      form: {
        name: { required },
        surname: { required },
        birthdate: {
          required,
          maxDate: helpers.withMessage(
            this.$t("form.birthdate_invalid"),
            maxDate
          ),
        },
        birthnumber: { required },
        idcard_no: { required },
        idcard_validity: {
          required,
          minDate: helpers.withMessage(
            this.$t("validations.idcard_date"),
            minDate
          ),
        },
        nationality: { required },
        phone: { required },
        smsCode: { required },
        child_investment: { required },
        intermediaryPerson: { required },
        // SFA: { required },
      },
    };
  },
  methods: {
    updateModel(value: string, model: string) {
      this.v$.form[model].$touch();
      this.form[model] = value;
    },
    sendSmsCode() {
      var params = new URLSearchParams();
      params.append("phone_number", this.form.phone);
      this.v$.form.phone.$touch();
      if (this.form.phone && !this.v$.form.phone.$error) {
        this.$store.dispatch("onboarding/sendSmsCode", {
          params,
        });
        this.disabledPhone = true;
      } else {
        this.$store.dispatch("alerts/error", {
          text: this.$t("form.phone_invalid"),
        });
      }
    },
    submitForm() {
      this.v$.$validate();
      const form = this.form;
      // console.log("form inter", form.intermediaryPerson[0]?.answer);
      console.log("Form", form);
      console.log("Form child", form.child_investment[0]?.answer);
      // console.log("form child", form.childInvestment[0]?.answer);
      // console.log("form", form);

      var params = new URLSearchParams();
      params.append("academic_title_before", form.title || "");
      params.append("name", form.name);
      params.append("surname", form.surname);
      params.append("academic_title_after", "");
      params.append("birthdate", form.birthdate);
      params.append("birthnumber", form.birthnumber);
      params.append("idcard_no", form.idcard_no);
      params.append("idcard_validity", form.idcard_validity);
      params.append("nationality", form.nationality);
      params.append("phone", form.phone);
      params.append("sms_code", form.smsCode);
      params.append("child_investment", form.child_investment[0]?.answer);
      params.append(
        "with_intermediary_person",
        form.intermediaryPerson[0]?.answer
      );
      params.append("sfa", form.SFA || "");
      if (form.is_from_wem) {
        params.append("is_from_wem", form.is_from_wem[0].answer || false);
      }
      this.$store.commit(
        "onboarding/setChild",
        form.child_investment[0]?.answer
      );
      this.formSubmitted = true;
      if (!this.v$.$invalid) {
        this.$store.dispatch("onboarding/sendPersonalInfo", params);
      }
    },
  },

  mounted() {
    // Check if referral data exists in local storage
    const referralData = localStorage.getItem("referral");
    const referralExp = localStorage.getItem("referral_exp");
    if (referralData && referralExp) {
      // Convert expiration timestamp to milliseconds
      const expirationTimestamp = Number(referralExp) * 1000;
      const currentTimestamp = Date.now();
      if (expirationTimestamp > currentTimestamp) {
        // Referral data is valid and not expired
        console.log(
          "Referral data is valid. Prefilling the intermediary person information."
        );
        // Prefill intermediary person information using referral data
        this.form.intermediaryPerson = [
          {
            answer: true,
            points: undefined,
            question: "intermediaryPerson",
            text: "",
          },
        ];
        // Prefill SFA field with referral data
        // const decodedReferralData = JSON.parse(atob(referralData.split('.')[1]));
        this.form.SFA = referralData;
      } else {
        // Referral data has expired, remove it from local storage
        console.log("Referral data has expired. Removing from local storage.");
        localStorage.removeItem("referral");
        localStorage.removeItem("referral_exp");
      }
    }
  },
})
export default class PersonalInfoForm extends Vue {}
