import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "text-primary-300 relative" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["name", "id", "value", "onInput"]
const _hoisted_4 = ["for"]
const _hoisted_5 = ["placeholder", "onInput"]
const _hoisted_6 = {
  key: 2,
  class: "text-danger text-xs mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(`block w-full text-sm mb-2 ${_ctx.labelClasses}`)
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(`block w-full text-sm mb-2 font-serif`),
          innerHTML: _ctx.description
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(`flex ${_ctx.classes}`)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("div", {
          key: option.value,
          class: _normalizeClass(`w-full flex items-center ${_ctx.optionClasses}`)
        }, [
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("input", {
              name: _ctx.name,
              id: `${_ctx.name}-${option.value}`,
              value: option.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selected = $event)),
              onInput: ($event: any) => (_ctx.updateModel(option)),
              type: "radio",
              class: "w-4 h-4 cursor-pointer bg-transparent focus:outline-none mt-0.5"
            }, null, 40, _hoisted_3), [
              [_vModelRadio, _ctx.selected]
            ]),
            _createElementVNode("label", {
              for: `${_ctx.name}-${option.value}`
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(`flex-1 flex items-center space-x-2 ${_ctx.labelClasses}`)
          }, [
            _createElementVNode("span", null, _toDisplayString(option.name), 1),
            (option.hasInput && _ctx.selected === option.value)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 0,
                  ref: "textInput",
                  placeholder: option.inputLabel,
                  class: "bg-transparent focus:outline-none border-b-2 border-secondary block w-full",
                  type: "text",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.textInputValue = $event)),
                  onInput: ($event: any) => (_ctx.updateCustomText(option, $event.target.value))
                }, null, 40, _hoisted_5)), [
                  [_vModelText, _ctx.textInputValue]
                ])
              : _createCommentVNode("", true)
          ], 2)
        ], 2))
      }), 128))
    ], 2),
    (_ctx.hasError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.error.$message), 1))
      : _createCommentVNode("", true)
  ]))
}