
import { Options, Vue } from "vue-class-component";
import { DatePicker } from "v-calendar";

@Options({
  data() {
    return {
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      masks: {
        input: "DD.MM.YYYY",
      },
    };
  },
  props: {
    model: {
      required: true,
    },
    label: {
      required: true,
      type: String,
    },
    textSize: {
      type: String,
      default: "sm",
    },
    hasError: {
      required: false,
      type: Boolean,
    },
    error: {
      required: false,
    },
  },
  components: {
    DatePicker,
  },
  methods: {
    updateModel(e: any) {
      this.$emit("updateModel", e.id);
    },
    updateModelValue(value: string) {
      if (value) {
        const date = value.split(".");
        const newDate = `${date[2]}-${date[1]}-${date[0]}`;
        this.$emit("updateModel", newDate);
      } else {
        this.$emit("updateModel", value);
      }
    },
  },
  computed: {
    modelValue() {
      return this.model;
    },
  },
})
export default class CalendarInput extends Vue {}
